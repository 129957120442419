import { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  Box,
  Zoom,
  Container,
  useMediaQuery,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import Chart from "../../components/Chart/Chart.jsx";
import { trim, formatCurrency } from "../../helpers";
import {
  treasuryDataQuery,
  rebasesDataQuery,
  bulletpoints,
  tooltipItems,
  tooltipInfoMessages,
  itemType,
} from "./treasuryData.js";
import { useTheme } from "@material-ui/core/styles";
import "./treasury-dashboard.scss";
import apollo from "../../lib/apolloClient";
import InfoTooltip from "src/components/InfoTooltip/InfoTooltip.jsx";

function TreasuryDashboard() {
  const [data, setData] = useState(null);
  const [apy, setApy] = useState(null);
  const [runway, setRunway] = useState(null);
  // const [staked, setStaked] = useState(null);
  const theme = useTheme();
  const smallerScreen = useMediaQuery("(max-width: 650px)");
  const verySmallScreen = useMediaQuery("(max-width: 379px)");

  const staked = useSelector((state) => {
    return state.app.Staked;
  });
  const treasuryMarketValue = useSelector((state) => {
    return state.app.treasuryMarketValue;
  });
  const marketPrice = useSelector((state) => {
    return state.app.marketPrice;
  });
  const circSupply = useSelector((state) => {
    return state.app.circSupply;
  });
  const totalSupply = useSelector((state) => {
    return state.app.totalSupply;
  });
  const marketCap = useSelector((state) => {
    return state.app.marketCap;
  });

  const currentIndex = useSelector((state) => {
    return state.app.currentIndex;
  });

  const backingPerOhm = useSelector((state) => {
    return state.app.treasuryMarketValue / state.app.circSupply;
  });
  const stakingAPY = useSelector((state) => {
    return state.app.stakingAPY;
  });
  const wsOhmPrice = useSelector((state) => {
    return state.app.marketPrice * state.app.currentIndex;
  });

  useEffect(() => {
    // apollo(treasuryDataQuery).then(r => {
    //   let metrics = r.data.protocolMetrics.map(entry =>
    //     Object.entries(entry).reduce((obj, [key, value]) => ((obj[key] = parseFloat(value)), obj), {}),
    //   );
    //   metrics = metrics.filter(pm => pm.treasuryMarketValue > 0);
    //   setData(metrics);
    //   let staked = r.data.protocolMetrics.map(entry => ({
    //     staked: (parseFloat(entry.sOhmCirculatingSupply) / parseFloat(entry.PIDCirculatingSupply)) * 100,
    //     timestamp: entry.timestamp,
    //   }));
    //   staked = staked.filter(pm => pm.staked < 100);
    //   setStaked(staked);
    //   let runway = metrics.filter(pm => pm.runway10k > 5);
    //   setRunway(runway);
    // });
    // apollo(rebasesDataQuery).then(r => {
    //   let apy = r.data.rebases.map(entry => ({
    //     apy: Math.pow(parseFloat(entry.percentage) + 1, 365 * 3) * 100,
    //     timestamp: entry.timestamp,
    //   }));
    //   apy = apy.filter(pm => pm.apy < 300000);
    //   setApy(apy);
    // });
  }, []);

  return (
    <div
      id="treasury-dashboard-view"
      className={`${smallerScreen && "smaller"} ${
        verySmallScreen && "very-small"
      }`}
    >
      <Container style={{ paddingLeft: "3.3rem", paddingRight: "3.3rem" }}>
        {/* <Box className={`hero-metrics`}>
          <Paper className="ohm-card">
            <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
              <Box className="metric market">
                <Typography variant="h6" color="textSecondary">
                  Market Cap
                </Typography>
                <Typography variant="h5">
                  {marketCap && formatCurrency(marketCap, 0)}
                  {!marketCap && <Skeleton type="text" />}
                </Typography>
              </Box>

              <Box className="metric price">
                <Typography variant="h6" color="textSecondary">
                  OHM Price
                </Typography>
                <Typography variant="h5">
                  {marketPrice ? formatCurrency(marketPrice, 2) : <Skeleton type="text" />}
                </Typography>
              </Box>

              <Box className="metric wsoprice">
                <Typography variant="h6" color="textSecondary">
                  wsOHM Price
                  <InfoTooltip
                    message={
                      "wsOHM = sDND * index\n\nThe price of wsOHM is equal to the price of OHM multiplied by the current index"
                    }
                  />
                </Typography>

                <Typography variant="h5">
                  {wsOhmPrice ? formatCurrency(wsOhmPrice, 2) : <Skeleton type="text" />}
                </Typography>
              </Box>

              <Box className="metric circ">
                <Typography variant="h6" color="textSecondary">
                  Circulating Supply (total)
                </Typography>
                <Typography variant="h5">
                  {circSupply && totalSupply ? (
                    parseInt(circSupply) + " / " + parseInt(totalSupply)
                  ) : (
                    <Skeleton type="text" />
                  )}
                </Typography>
              </Box>

              <Box className="metric bpo">
                <Typography variant="h6" color="textSecondary">
                  Backing per OHM
                </Typography>
                <Typography variant="h5">
                  {backingPerOhm ? formatCurrency(backingPerOhm, 2) : <Skeleton type="text" />}
                </Typography>
              </Box>

              <Box className="metric index">
                <Typography variant="h6" color="textSecondary">
                  Current Index
                  <InfoTooltip
                    message={
                      "The current index tracks the amount of sDND accumulated since the beginning of staking. Basically, how much sDND one would have if they staked and held a single OHM from day 1."
                    }
                  />
                </Typography>
                <Typography variant="h5">
                  {currentIndex ? trim(currentIndex, 2) + " sDND" : <Skeleton type="text" />}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box> */}

        <Zoom in={true}>
          <Grid container spacing={2} className="data-grid">
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <Typography className="contentStyle">
                  {/* appleseed-fix */}
                  {marketPrice ? (
                    formatCurrency(marketPrice, 2)
                  ) : (
                    <Skeleton type="text"></Skeleton>
                  )}
                </Typography>
                <Typography className="titleColor">
                  veDND Price
                </Typography>
                {/* <Chart
                  type="area"
                  data={data}
                  dataKey={["totalValueLocked"]}
                  stopColor={[["#768299", "#98B3E9"]]}
                  headerText="Total Value Deposited"
                  headerSubText={`${
                    data && formatCurrency(data[0]?.totalValueLocked)
                  }`}
                  bulletpointColors={bulletpoints.tvl}
                  itemNames={tooltipItems.tvl}
                  itemType={itemType.dollar}
                  infoTooltipMessage={tooltipInfoMessages.tvl}
                  expandedGraphStrokeColor={theme.palette.graphStrokeColor}
                /> */}
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <Typography className="contentStyle">
                  {currentIndex ? (
                    trim(currentIndex, 2) + " sDND"
                  ) : (
                    <Skeleton type="text" />
                  )}
                </Typography>
                <Typography className="titleColor">
                  Current Index
                  <InfoTooltip
                    message={
                      "The current index tracks the amount of sDND accumulated since the beginning of staking. Basically, how much sDND one would have if they staked and held a single veDND from day 1."
                    }
                  />
                </Typography>
                {/* <Chart
                    type="stack"
                    data={data}
                    dataKey={[
                      "treasuryDaiMarketValue",
                      "treasuryFraxMarketValue",
                      "treasuryWETHMarketValue",
                      "treasuryXsushiMarketValue",
                    ]}
                    stopColor={[
                      ["#F5AC37", "#EA9276"],
                      ["#768299", "#98B3E9"],
                      ["#DC30EB", "#EA98F1"],
                      ["#8BFF4D", "#4C8C2A"],
                    ]}
                    headerText="Market Value of Treasury Assets"
                    headerSubText={`${data && formatCurrency(data[0]?.treasuryMarketValue)}`}
                    bulletpointColors={bulletpoints.coin}
                    itemNames={tooltipItems.coin}
                    itemType={itemType.dollar}
                    infoTooltipMessage={tooltipInfoMessages.mvt}
                    expandedGraphStrokeColor={theme.palette.graphStrokeColor}
                  /> */}
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <Typography className="contentStyle">
                  {circSupply && totalSupply ? (
                    trim(circSupply, 2) + " / " + trim(totalSupply, 2)
                  ) : (
                    <Skeleton type="text" />
                  )}
                </Typography>
                <Typography className="titleColor">
                  Circulating Supply
                </Typography>
                {/* <Chart
                    type="stack"
                    data={data}
                    format="currency"
                    dataKey={["treasuryDaiRiskFreeValue", "treasuryFraxRiskFreeValue"]}
                    stopColor={[
                      ["#F5AC37", "#EA9276"],
                      ["#768299", "#98B3E9"],
                      ["#000", "#fff"],
                      ["#000", "#fff"],
                    ]}
                    headerText="Risk Free Value of Treasury Assets"
                    headerSubText={`${data && formatCurrency(data[0]?.treasuryRiskFreeValue)}`}
                    bulletpointColors={bulletpoints.coin}
                    itemNames={tooltipItems.coin}
                    itemType={itemType.dollar}
                    infoTooltipMessage={tooltipInfoMessages.rfv}
                    expandedGraphStrokeColor={theme.palette.graphStrokeColor}
                  /> */}
              </Paper>
            </Grid>
            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <Chart
                  type="area"
                  data={data}
                  dataKey={["treasuryOhmDaiPOL"]}
                  stopColor={[["rgba(128, 204, 131, 1)", "rgba(128, 204, 131, 0)"]]}
                  headerText="Protocol Owned Liquidity OHM-DAI"
                  headerSubText={`${data && trim(data[0]?.treasuryOhmDaiPOL, 2)}% `}
                  dataFormat="percent"
                  bulletpointColors={bulletpoints.pol}
                  itemNames={tooltipItems.pol}
                  itemType={itemType.percentage}
                  infoTooltipMessage={tooltipInfoMessages.pol}
                  expandedGraphStrokeColor={theme.palette.graphStrokeColor}
                  isPOL={true}
                />
              </Paper>
            </Grid> */}
            {/*  Temporarily removed until correct data is in the graph */}
            {/* <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper className="ohm-card">
                <Chart
                  type="bar"
                  data={data}
                  dataKey={["holders"]}
                  headerText="Holders"
                  stroke={[theme.palette.text.secondary]}
                  headerSubText={`${data && data[0].holders}`}
                  bulletpointColors={bulletpoints.holder}
                  itemNames={tooltipItems.holder}
                  itemType={""}
                  infoTooltipMessage={tooltipInfoMessages.holder}
                  expandedGraphStrokeColor={theme.palette.graphStrokeColor}
                />
              </Paper>
            </Grid> */}

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <Typography className="contentStyle">
                  {backingPerOhm ? (
                    formatCurrency(backingPerOhm, 2)
                  ) : (
                    <Skeleton type="text" />
                  )}
                </Typography>
                <Typography  className="titleColor">
                  Backing per veDND
                </Typography>
                {/* <Chart
                  type="area"
                  data={staked}
                  dataKey={["staked"]}
                  stopColor={[["#55EBC7", "#47ACEB"]]}
                  headerText="OHM Staked"
                  dataFormat="percent"
                  headerSubText={`${staked && trim(staked[0]?.staked, 2)}% `}
                  isStaked={true}
                  bulletpointColors={bulletpoints.staked}
                  infoTooltipMessage={tooltipInfoMessages.staked}
                  expandedGraphStrokeColor={theme.palette.graphStrokeColor}
                /> */}
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <Typography className="contentStyle">
                  {/* appleseed-fix */}
                  {treasuryMarketValue ? (
                    formatCurrency(treasuryMarketValue, 2)
                  ) : (
                    <Skeleton type="text" />
                  )}
                </Typography>
                <Typography className="titleColor">
                  Treasury Balance
                </Typography>
                {/* <Chart
                  type="line"
                  scale="log"
                  data={apy}
                  dataKey={["apy"]}
                  color={theme.palette.text.primary}
                  stroke={[theme.palette.text.primary]}
                  headerText="APY over time"
                  dataFormat="percent"
                  headerSubText={`${apy && trim(apy[0]?.apy, 2)}%`}
                  bulletpointColors={bulletpoints.apy}
                  itemNames={tooltipItems.apy}
                  itemType={itemType.percentage}
                  infoTooltipMessage={tooltipInfoMessages.apy}
                  expandedGraphStrokeColor={theme.palette.graphStrokeColor}
                /> */}
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <Typography className="contentStyle">
                  {staked ? `${trim(staked, 2)}%` : <Skeleton type="text" />}
                </Typography>
                <Typography className="titleColor">
                  veDND Staked
                </Typography>
                {/* <Chart
                  type="line"
                  data={runway}
                  dataKey={["runwayCurrent"]}
                  color={theme.palette.text.primary}
                  stroke={[theme.palette.text.primary]}
                  headerText="Runway Available"
                  headerSubText={`${data && trim(data[0]?.runwayCurrent, 1)} Days`}
                  dataFormat="days"
                  bulletpointColors={bulletpoints.runway}
                  itemNames={tooltipItems.runway}
                  itemType={""}
                  infoTooltipMessage={tooltipInfoMessages.runway}
                  expandedGraphStrokeColor={theme.palette.graphStrokeColor}
                /> */}
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <Typography className="contentStyle">
                  {stakingAPY ? (
                    `${trim(stakingAPY * 100, 2)}%`
                  ) : (
                    <Skeleton type="text" />
                  )}
                </Typography>
                <Typography className="titleColor">
                  APY
                </Typography>
                {/* <Chart
                  type="line"
                  data={runway}
                  dataKey={["runwayCurrent"]}
                  color={theme.palette.text.primary}
                  stroke={[theme.palette.text.primary]}
                  headerText="Runway Available"
                  headerSubText={`${data && trim(data[0]?.runwayCurrent, 1)} Days`}
                  dataFormat="days"
                  bulletpointColors={bulletpoints.runway}
                  itemNames={tooltipItems.runway}
                  itemType={""}
                  infoTooltipMessage={tooltipInfoMessages.runway}
                  expandedGraphStrokeColor={theme.palette.graphStrokeColor}
                /> */}
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <Typography className="contentStyle">
                  {marketCap && formatCurrency(marketCap, 2)}
                  {!marketCap && <Skeleton type="text" />}
                </Typography>
                <Typography className="titleColor">
                  Market Cap
                </Typography>
                {/* <Chart
                  type="line"
                  data={runway}
                  dataKey={["runwayCurrent"]}
                  color={theme.palette.text.primary}
                  stroke={[theme.palette.text.primary]}
                  headerText="Runway Available"
                  headerSubText={`${data && trim(data[0]?.runwayCurrent, 1)} Days`}
                  dataFormat="days"
                  bulletpointColors={bulletpoints.runway}
                  itemNames={tooltipItems.runway}
                  itemType={""}
                  infoTooltipMessage={tooltipInfoMessages.runway}
                  expandedGraphStrokeColor={theme.palette.graphStrokeColor}
                /> */}
              </Paper>
            </Grid>
          </Grid>
        </Zoom>
      </Container>
    </div>
  );
}

export default TreasuryDashboard;
