import { ReactComponent as ForumIcon } from "../../assets/icons/forum.svg";
import { ReactComponent as GovIcon } from "../../assets/icons/governance.svg";
import { ReactComponent as DocsIcon } from "../../assets/icons/docs.svg";
import { ReactComponent as FeedbackIcon } from "../../assets/icons/feedback.svg";
import { SvgIcon } from "@material-ui/core";
import styled from "styled-components";

const externalUrls = [
  {
    title: "Governance",
    url: "https://snapshot.org/#/dynodao.eth",
    icon: <SvgIcon color="primary" component={GovIcon} />,
  },
  {
    title: "Docs",
    url: "https://docs.dynochain.io/",
    icon: <SvgIcon color="primary" component={DocsIcon} />,
  },
  {
    title: "Forum",
    url: "https://forum.dynochain.io",
    icon: <SvgIcon color="primary" component={FeedbackIcon} />,
  },
];

export default externalUrls;

const IconImg1 = styled.img``;
const IconImg2 = styled.img``;
const IconImg3 = styled.img``;
